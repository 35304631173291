<template>
	<div>
		<div class="allcrmbox">
			<div class="leftcrmlist">
				<div
					class="oneicon"
					v-for="(item, index) in routerList"
					:key="item.name"
					@click="choosePath(item, index)"
				>
					<img :src="item.meta.icon" v-if="index !== leftIndex" />
					<img :src="item.meta.activeicon" v-if="index === leftIndex" />
					<span :class="{ activeleft: index === leftIndex }">{{ item.meta.text }}</span>
				</div>
			</div>
			<div class="rightcontent"><router-view></router-view></div>
		</div>
	</div>
</template>

<script>
import { userPermissionTree } from '@/network/api.js';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			routerList: [],
			// listindex: 0,
			leftIndex: 0
		};
	},
	name: 'CRM',
	computed: {
		...mapState(['userInfo', 'permissionList', 'isadmin', 'topIndex'])
	},
	watch: {
		$route() {
			this.getRoutePath()
		}
	},
	created() {
		this.$store.commit('setTopIndex', 3);
		this.$store.dispatch('_userPermissionTree', this.getRouterList());
	},
	methods: {
		// 点击侧边栏跳转
		choosePath(item, index) {
			this.leftIndex = index;
			this.$router.push({
				path: item.path
			});
		},
		// 获取当前路由地址
		getRoutePath() {
			let route = this.$route.path;

			this.routerList.forEach((item, index) => {
				if (item.path === route) {
					this.leftIndex = index;
				} else if (item.meta.childrenPath && item.meta.childrenPath.includes(route)) {
					this.leftIndex = index;
				} else {
				}
			});
		},
		// 获取路由列表
		getRouterList() {
			let routerlist = this.$router.options.routes.filter(item => item.path === '/')[0].children[4]
				.children;
			
			if (this.$isAdmin()) {
				/// 如果是总帐号
				this.routerList = routerlist.filter(item => {
					if (item.meta && item.meta.level === 3) {
						return item;
					}
				});
			} else {
				routerlist.forEach(item => {
					if (item.meta && item.meta.level === 3 && this.permissionList.includes(item.name)) {
							this.routerList.push(item);
					}
				});
			}
			this.getRoutePath();
		}
	}
};
</script>

<style scoped lang="scss">
.oneicon {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0 0 1.875em 0;
	img {
		width: 1.25em;
		height: 1.25em;
		margin: 0 0 0.3125em 0;
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
		&.activeleft {
			color: #2f79ff !important;
		}
	}
}
.rightcontent {
	height: calc(100vh - 5em);
	width: calc(100vw - 3.75em);
	overflow-y: scroll;
}
.leftcrmlist {
	padding: 2.125em 0 0;
	background-color: #fff;
	width: 3.75em;
	height: calc(100vh - 5em);
}
.allcrmbox {
	display: flex;
}
@media screen and (max-width: 1920px) {
	.allcrmbox {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.allcrmbox {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.allcrmbox {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.allcrmbox {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.allcrmbox {
		font-size: 12px;
	}
}
</style>
